import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>
                        Edit <code>src/App.js</code> and save to reload.
                    </p>
                    <div className="App-intro">
                        <TodoList />
                    </div>
                </header>
            </div>
            );
}

class TodoList extends React.Component {
    state = {
        items: []
    };
    //Nun erstellen wir in TodoList eine Funktion, die beim Klick auf den Hinzufügen-Button ausgelöst werden soll.
    addItem = e => {
        const itemArray = this.state.items;
        itemArray.push({
            text: this._inputElement.value,
            key: Date.now()
        });

        this.setState({
            items: itemArray
        });

        this._inputElement.value = "";
        e.preventDefault();
    };
    render() {
        return (
                <div className="todoListMain">
                    <div className="header">
                        <form onSubmit={this.addItem}>
                            <label>
                                Zu erledigen: 
                                <input ref={input => (this._inputElement = input)} />
                            </label>
                            <button type="submit">
                                Hinzufügen
                            </button>
                        </form>
                        <TodoItems entries={this.state.items} />
                    </div>
                </div>
                );
    }
}
class TodoItems extends React.Component {
  render() {
 
    var todoEntries = this.props.entries;
    function createTodos(item) {
      return (
        <li key={item.key}>
          {item.text}
        </li>
      );
    }
    var listItems = todoEntries.map(createTodos);
    return (
      <ul>
        {listItems}
      </ul>
    );
  }
}
export default App;
